import { FC, memo, useMemo } from 'react';
import DateUtils from '../../../utils/DateUtils';

const DateRenderer: FC<{ data: string | null }> = (props) => {
  const date = useMemo(() => props.data && new Date(props.data), [props]);

  return <>{date ? DateUtils.formatDateTime(date) : '-'}</>;
};

export default memo(DateRenderer);
